@mixin responsive($breakpoint) {
    @if $breakpoint==mobile {
        @media (max-width: 392px) {
            @content;
        }
    }
    @if $breakpoint==mobile11 {
        @media (max-width: 500px) {
            @content;
        }
    }
    @if $breakpoint==desktop {
        @media (min-width: 1400px) {
            @content;
        }
    }
    @if $breakpoint==big {
        @media (max-width: 1260px) {
            @content;
        }
    }
    @if $breakpoint==toqqiz {
        @media (max-width: 900px) {
            @content;
        }
    }
    @if $breakpoint==uch {
        @media (max-width: 320px) {
            @content;
        }
    }
}

.platform {
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    width: 100%;
    margin: 0 auto;
    @include responsive(desktop) {
        width: 100%;
    }
    @include responsive(big) {
        width: 100%;
    }
    @include responsive(toqqiz) {
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    @include responsive(mobile) {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 27rem;
    }
    &__left {
        position: relative;
        margin-top: 107px;
        margin-left: 177px;
        @include responsive(big) {
            margin-left: 70px;
        }
        @include responsive(toqqiz) {
            margin-left: 0px;
            margin-top: 2rem;
        }
        @include responsive(mobile) {
            margin-left: 2rem;
            margin-top: 10px;
        }
        &--title {
            font-size: 4rem;
            font-weight: 900;
            line-height: 1.4;
            letter-spacing: 0.1rem;
            margin-bottom: 4rem;
            width: 550px;
            @include responsive(big) {
                font-size: 2.4rem;
                width: 100%;
            }
            @include responsive(toqqiz) {
                font-size: 20px;
                margin-bottom: 2rem;
                width: 350px;
                display: block;
                margin: 0 auto;
            }
            @include responsive(mobile) {
                display: block;
                margin: 0 auto;
                font-size: 16px;
                text-align: left;
                margin-bottom: 1rem;
                // padding: 15px;
                width: 250px;
            }
            @include responsive(uch) {
                display: block;
                margin: 0 auto;
                font-size: 14px;
                padding: 15px;
                margin-bottom: 0rem;
                width: 250px;
            }
        }
        &--parag {
            &--1,
            &--2,
            &--3,
            &--4 {
                // margin: 2rem auto;
                font-size: 2.4rem;
                line-height: 1.4;
                width: 590px;
                @include responsive(big) {
                    width: 100%;
                    font-size: 1.8rem;
                    margin: 0;
                }
                @include responsive(toqqiz) {
                    width: 350px;
                    font-size: 14px;
                    font-weight: 500;
                    display: block;
                    margin: 0 auto;
                }
                @include responsive(mobile) {
                    font-size: 13px;
                    width: 250px;
                }
            }
        }
        &--more--button {
            margin-top: 4rem;
            margin-bottom: 103px;
            // margin-left: auto;
            a {
                text-decoration: none;
            }
            @include responsive(toqqiz) {
                margin-top: 2rem;
            }
            @include responsive(toqqiz) {
                display: flex;
                margin-bottom: 1rem;
            }
            @include responsive(mobile) {
                margin-bottom: 1rem;
            }
            button {
                border: none;
                text-align: center;
                background-color: #ffdea3;
                color: #fff;
                width: 22.8rem;
                height: 4.9rem;
                border-radius: 1.2rem;
                font-weight: 800;
                margin: 0 auto;
                padding: 0 0.3rem;
                cursor: pointer;
                @include responsive(big) {
                    width: 12rem;
                    height: 3.4rem;
                    border-radius: 0.8rem;
                }
                @include responsive(big) {
                    width: 14rem;
                    height: 4.4rem;
                    border-radius: 0.8rem;
                }
                @include responsive(mobile) {
                    width: 90px;
                    height: 30px;
                    font-size: 0.6rem;
                }
            }
        }
        &--img--1 {
            position: absolute;
            height: 4.4rem;
            width: 4.4rem;
            transform: rotate(15deg);
            right: -16%;
            bottom: 100px;
            @include responsive(big) {
                width: 2rem;
                height: 2rem;
            }
            @include responsive(toqqiz) {
                width: 2rem;
                height: 2rem;
                right: 2rem;
                top: 260px;
            }
            @include responsive(mobile) {
                width: 20.47px;
                height: 20.47px;
                bottom: 0;
                top: 90px;
                right: 20px;
            }
        }
        &--img--2 {
            position: absolute;
            height: 4.4rem;
            width: 4.4rem;
            left: -18%;
            top: 25%;
            @include responsive(big) {
                width: 2rem;
                height: 2rem;
                left: 1rem;
            }
            @include responsive(toqqiz) {
                display: none;
            }
            transform: rotate(15deg);
            @include responsive(mobile) {
                width: 20.47px;
                height: 20.47px;
                left: 2px;
                display: none;
            }
        }
    }
    &__right {
        position: relative;
        &--img--1 {
            position: absolute;
            height: 261px;
            width: 435px;
            top: 104px;
            right: 190px;
            @include responsive(big) {
                height: 200px;
                width: 350px;
                right: 120px;
            }
            @include responsive(toqqiz) {
                position: relative;
                display: block;
                margin: 0 auto;
                height: 200px;
                width: 300px;
                right: 0;
                top: -20px;
            }
            @include responsive(mobile) {
                position: absolute;
                width: 200px;
                height: 120px;
                right: 125px;
                top: 0;
            }
            @include responsive(uch) {
                position: absolute;
                width: 200px;
                height: 120px;
                right: 85px;
                top: 0;
            }
        }
        &--img--2 {
            position: absolute;
            height: 286px;
            width: 421px;
            top: 370px;
            right: 80px;
            @include responsive(big) {
                height: 200px;
                width: 350px;
                top: 300px;
                right: 40px;
            }
            @include responsive(toqqiz) {
                position: relative;
                display: block;
                margin: 0 auto;
                height: 200px;
                right: 0;
                width: 300px;
                top: -20px;
                left: 10px;
            }
            @include responsive(mobile) {
                position: absolute;
                width: 193px;
                height: 107px;
                top: 120px;
                right: 50px;
            }
            @include responsive(uch) {
                position: absolute;
                width: 193px;
                height: 107px;
                top: 120px;
                right: 30px;
            }
        }
        &--img--3 {
            position: absolute;
            height: 384px;
            width: 408px;
            top: 218px;
            right: 70px;
            @include responsive(big) {
                height: 284px;
                width: 308px;
                top: 160px;
                right: 40px;
            }
            @include responsive(toqqiz) {
                position: relative;
                display: block;
                margin: 0 auto;
                height: 160px;
                width: 260px;
                right: 0;
                top: -300px;
                left: 70px;
            }
            @include responsive(mobile) {
                position: absolute;
                width: 182px;
                height: 186px;
                top: 30px;
                right: 60px;
            }
        }
        &--img--4 {
            position: absolute;
            height: 4.2rem;
            width: 4.2rem;
            right: 91px;
            top: 50px;
            transform: rotate(20deg);
            @include responsive(toqqiz) {
                width: 20.47px;
                height: 20.47px;
                // display: none;
                right: 61px;
            }
            @include responsive(mobile11) {
                width: 20.47px;
                height: 20.47px;
                display: none;
                right: 61px;
            }
            @include responsive(mobile) {
                width: 20.47px;
                height: 20.47px;
                display: none;
                right: 61px;
            }
        }
        &--img--5 {
            position: absolute;
            height: 4.2rem;
            width: 4.2rem;
            bottom: 50px;
            right: 190px;
            transform: rotate(20deg);
            @include responsive(toqqiz) {
                width: 2.4rem;
                height: 2.47rem;
                bottom: 0;
                top: 17rem;
                right: 4px;
                left: 6rem;
            }
            @include responsive(mobile) {
                width: 2.4rem;
                height: 2.47rem;
                bottom: 0;
                top: 17rem;
                right: 0px;
                left: 0rem;
            }
            @include responsive(mobile) {
                display: none;
            }
        }
        ///////////////////////
        &--img--6 {
            z-index: 200;
            position: absolute;
            height: 14.2rem;
            width: 14.2rem;
            top: 260px;
            right: 52px;
            @include responsive(big) {
                height: 120px;
                width: 140px;
                top: 210px;
                right: 10px;
            }
            @include responsive(toqqiz) {
                display: none;
                height: 100px;
                width: 100px;
                top: 140px;
                right: 200px;
            }
            @include responsive(mobile) {
                display: inline;
                width: 104px;
                height: 107px;
                top: 30px;
                right: 60px;
                z-index: -100;
            }
        }
        &--img--7 {
            position: absolute;
            height: 4.2rem;
            width: 4.2rem;
            top: 330px;
            left: 45px;
            @include responsive(big) {
                display: none;
            }
            @include responsive(mobile) {
                display: block;
                width: 20.47px;
                height: 20.47px;
                top: 100px;
                left: 37px;
            }
        }
        &--button {
            position: absolute;
            text-align: center;
            display: flex;
            background-color: #fff;
            height: 4rem;
            width: 19.5rem;
            border-radius: 1.1rem;
            background-color: #fff;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            top: 623px;
            right: 258px;
            cursor: pointer;
            @include responsive(big) {
                top: 480px;
                right: 200px;
            }
            @include responsive(toqqiz) {
                top: 350px;
                right: 180px;
            }
            @include responsive(mobile) {
                right: 0;
                width: 150px;
                height: 30px;
                top: 210px;
                right: 150px;
            }
            img {
                height: 90%;
                padding-left: 1rem;
                padding-top: 0.2rem;
            }
            p {
                text-align: center;
                font-size: 1.6rem;
                font-weight: 900;
                padding-top: 1.1rem;
                padding-left: 1rem;
                @include responsive(mobile) {
                    font-size: 1rem;
                    padding-top: 0.6rem;
                }
            }
        }
    }
}

.platform__modal {
    margin-left: -12rem;
    @include responsive(big) {
        margin-left: 1rem;
    }
}

.hides__parag {
    p {
        margin-left: 10.5rem;
        font-size: 2.4rem;
        @include responsive(big) {
            font-size: 1.8rem;
            margin: 0 auto;
            // margin-left: 61px;
        }
        @include responsive(toqqiz) {
            width: 350px;
            font-size: 14px;
            font-weight: 500;
            display: block;
            margin: 0 auto;
        }
        @include responsive(mobile) {
            font-size: 13px;
            width: 250px;
            line-height: 16px;
            margin: 0 auto;
        }
    }
    @include responsive(toqqiz) {
        width: 350px;
        font-size: 14px;
        font-weight: 500;
        display: block;
        margin: 0 auto;
        width: 100%;
    }
    @include responsive(desktop) {
        margin-left: 7rem;
        font-size: 1.6rem;
        width: 350px;
        font-size: 14px;
        font-weight: 500;
    }
    @include responsive(mobile) {
        margin: 0 auto;
    }
    button {
        display: block;
        margin: 0 auto;
        margin-top: 3rem;
        margin-left: 210px;
        @include responsive(toqqiz) {
            margin-left: 100px;
        }
        @include responsive(mobile) {
            width: 120px;
            height: 45px;
            font-size: 9px;
            margin-left: 80px;
        }
    }
}