@mixin responsive($breakpoint) {
    @if $breakpoint==mobile1 {
        @media (max-width: 385px) {
            @content;
        }
    }
    @if $breakpoint==mobile {
        @media (max-width: 600px) {
            @content;
        }
    }
    @if $breakpoint==mobile11 {
        @media (max-width: 470px) {
            @content;
        }
    }
    @if $breakpoint==desktop {
        @media (min-width: 1400px) {
            @content;
        }
    }
    @if $breakpoint==big {
        @media (max-width: 1260px) {
            @content;
        }
    }
    @if $breakpoint==ming {
        @media (max-width: 1000px) {
            @content;
        }
    }
    @if $breakpoint==yettiyuz {
        @media (max-width: 790px) {
            @content;
        }
    }
    @if $breakpoint==uch {
        @media (max-width: 376px) {
            @content;
        }
    }
    @if $breakpoint==minMobile {
        @media (max-width: 355px) {
            @content;
        }
    }
    @if $breakpoint==minimumMobile {
        @media (max-width: 325px) {
            @content;
        }
    }
    @if $breakpoint==minimumMobileimg {
        @media (max-width: 340px) {
            @content;
        }
    }
}

.header__box {
    width: 100%;
    display: block;
    margin: 0 auto;
    // height: 957px;
    @include responsive(desktop) {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
    @include responsive(big) {
        width: 100%;
        margin: 0 auto;
    }
    @include responsive(mobile) {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

.header {
    background-color: #181e30;
    height: 900px;
    color: #ffffff;
    position: relative;
    margin: 0 auto;
    @include responsive(big) {
        height: 850px;
    }
    @include responsive(ming) {
        height: 700px;
    }
    @include responsive(yettiyuz) {
        height: 450px;
    }
    @include responsive(mobile) {
        width: 100%;
        height: 715px;
    }
    @include responsive(mobile) {
        width: 100%;
        height: 615px;
    }
}

.header__nav {
    display: flex;
    justify-content: space-between;
    &--number {
        position: absolute;
        z-index: 10;
        display: inline-block;
        font-size: 2rem;
        top: 61px;
        right: 138px;
        text-decoration: none;
        color: #ffffff;
        @include responsive(big) {
            font-size: 17px;
            right: 80px;
            top: 40px;
        }
        @include responsive(yettiyuz) {
            font-size: 14px;
        }
        @include responsive(mobile) {
            position: absolute;
            right: 35px;
            top: 36px;
            font-weight: 700;
            font-size: 13px;
            line-height: 20px;
            right: 6rem;
        }
    }
    &--logo {
        img {
            height: 159px;
            width: 159px;
            top: 28px;
            margin-left: 149px;
            @include responsive(big) {
                margin-left: 90px;
            }
            @include responsive(ming) {
                margin-left: 50px;
                width: 120px;
                height: 120px;
            }
            @include responsive(yettiyuz) {
                width: 100px;
                height: 100px;
                margin-left: 20px;
            }
            @include responsive(mobile) {
                height: 91px;
                width: 91px;
                margin-left: 37px;
                top: 0;
            }
            @include responsive(uch) {
                height: 91px;
                width: 91px;
                margin-left: 10px;
                top: 0;
            }
        }
    }
}

.header__main {
    display: flex;
    justify-content: space-between;
    @include responsive(mobile) {
        flex-direction: column;
    }
    &--left {
        margin-top: 10px;
        position: relative;
        @include responsive(mobile) {
            margin-left: 42px;
        }
        @include responsive(uch) {
            margin-left: 22px;
        }
        &--parag--1 {
            z-index: 200;
            width: 697px;
            font-size: 40px;
            letter-spacing: 2px;
            line-height: 60px;
            margin-left: 17rem;
            width: 100%;
            @include responsive(big) {
                font-size: 30px;
                margin-left: 100px;
                width: 570px;
            }
            @include responsive(ming) {
                font-size: 18px;
                line-height: 1.4;
                margin-left: 60px;
            }
            @include responsive(yettiyuz) {
                font-size: 12px;
                margin-left: 29px;
                width: 300px;
            }
            @include responsive(mobile) {
                font-size: 18px;
                line-height: 27px;
                width: 87%;
                margin-left: 0.2rem;
                letter-spacing: 0px;
                margin-top: 2rem;
            }
            @include responsive(uch) {
                font-size: 18px;
                width: 90%;
                margin-left: 0rem;
                letter-spacing: 0px;
                margin-top: 0rem;
            }
        }
        &--parag--2 {
            margin-top: 65px;
            font-size: 19px;
            line-height: 28px;
            margin-left: 276px;
            width: 520px;
            @include responsive(big) {
                font-size: 16px;
                margin-left: 170px;
                width: 450px;
            }
            @include responsive(ming) {
                font-size: 14px;
                line-height: 18px;
                margin-left: 90px;
                width: 360px;
            }
            @include responsive(yettiyuz) {
                font-size: 11px;
                width: 280px;
                margin-left: 50px;
                margin-top: 30px;
            }
            // @include responsive(mobile11) {
            //     margin-left: 100px;
            // }
            @include responsive(mobile11) {
                font-size: 10px;
                margin: 0;
                width: 261px;
                // margin-left: 4.5rem;
                margin-top: 2rem;
                line-height: 15px;
            }
            @include responsive(uch) {
                font-size: 10px;
                margin: 0;
                width: 80%;
                margin-left: 4px;
                margin-top: 1rem;
                line-height: 15px;
            }
        }
        &--buttons {
            display: flex;
            z-index: 100;
            @include responsive(mobile) {}
            &--1 {
                display: inline-block;
                text-decoration: none;
                background-color: #fbd289;
                width: 20rem;
                text-align: center;
                font-size: 1.4rem;
                padding: 1rem;
                border-radius: 0.6rem;
                cursor: pointer;
                color: black;
                font-weight: 900;
                margin-left: 200px;
                margin-top: 110px;
                z-index: 100;
                @include responsive(big) {
                    margin-left: 120px;
                    margin-top: 80px;
                }
                @include responsive(ming) {
                    margin-top: 40px;
                    width: 16rem;
                    font-size: 1rem;
                    margin-left: 90px;
                }
                @include responsive(yettiyuz) {
                    margin-left: 50px;
                    margin-top: 20px;
                    width: 140px;
                    font-size: 0.8rem;
                    padding: 0.4rem;
                }
                @include responsive(mobile) {
                    z-index: 100;
                    margin-left: 3px;
                    margin-top: 32px;
                    width: 12rem;
                    font-size: 0.7rem;
                }
            }
            &--2 {
                font-weight: 900;
                color: #ffffff;
                text-decoration: none;
                cursor: pointer;
                font-size: 1.6rem;
                margin-left: 85px;
                line-height: 25.5px;
                z-index: 100;
                @include responsive(ming) {
                    margin-left: 50px;
                    font-size: 1.3rem;
                }
                @include responsive(yettiyuz) {
                    margin-left: 50px;
                    font-size: 1rem;
                }
                @include responsive(mobile) {
                    z-index: 100;
                    margin-left: 45px;
                    margin-top: 32px;
                    width: 10rem;
                    font-size: 1rem;
                }
                @include responsive(minMobile) {
                    z-index: 100;
                    margin-left: 16px;
                    margin-top: 22px;
                    width: 1rem;
                    font-size: 0.7rem;
                }
            }
        }
    }
}

.img {
    &-1 {
        position: absolute;
        right: 1%;
        top: 15%;
        height: 45rem;
        z-index: 9;
        @include responsive(big) {
            height: 32rem;
        }
        @include responsive(ming) {
            height: 25rem;
        }
        @include responsive(mobile) {
            height: 200px;
            width: 181px;
            left: 140px;
            top: 59%;
        }
        @include responsive(uch) {
            left: 111px;
            top: 303px;
        }
        @include responsive(minMobile) {
            height: 120px;
            left: 90px;
            top: 370px;
            height: 16rem;
        }
        @include responsive(minimumMobile) {
            left: 95px;
            top: 383px;
        }
    }
    &-2 {
        position: absolute;
        right: 9%;
        top: 0;
        height: 25rem;
        z-index: 9;
        @include responsive(big) {
            height: 20rem;
        }
        @include responsive(mobile) {
            right: 7px;
            top: 343px;
            height: 120px;
            width: 120px;
        }
        @include responsive(mobile1) {
            display: none;
        }
        @include responsive(uch) {
            display: none;
        }
    }
    &-3 {
        position: absolute;
        right: 31rem;
        top: 2rem;
        height: 247px;
        width: 303px;
        z-index: 9;
        @include responsive(big) {
            right: 200px;
        }
        @include responsive(ming) {
            height: 150px;
            width: 200px;
        }
        @include responsive(mobile) {
            left: -30px;
            top: 360px;
            height: 125px;
            width: 165px;
        }
        @include responsive(uch) {
            left: -40px;
            top: 330px;
            height: 147px;
            width: 183px;
        }
        @include responsive(minimumMobileimg) {
            left: -40px;
            top: 350px;
            height: 127px;
            width: 153px;
            display: none;
        }
    }
    &-4 {
        position: absolute;
        right: 0%;
        top: 3%;
        height: 40rem;
        z-index: 9;
        display: none;
        @include responsive(mobile) {
            display: block;
            position: absolute;
            right: 20px;
            top: 110px;
            height: 5rem;
            z-index: 9;
        }
    }
    &-5 {
        position: absolute;
        right: 18%;
        top: 52%;
        height: 39rem;
        z-index: 9;
        @include responsive(mobile) {
            left: -8%;
            top: 62%;
            height: 180px;
            width: 183px;
        }
        @include responsive(uch) {
            left: -22px;
            top: 375px;
        }
    }
    &-6 {
        ///////////////////////////////
        position: absolute;
        // right: 1035px;
        left: 42rem;
        top: 610px;
        height: 5rem;
        z-index: 9;
        @include responsive(big) {
            top: 62%;
            display: none;
        }
        @include responsive(ming) {
            top: 49%;
            right: 72%;
            height: 4rem;
        }
        @include responsive(yettiyuz) {
            top: 256px;
            right: 547px;
            height: 4rem;
        }
        @include responsive(mobile) {
            left: 163px;
            top: 292px;
            height: 25px;
            width: 24px;
        }
        @include responsive(uch) {
            left: 146px;
            top: 228px;
            height: 25px;
            width: 24px;
        }
        @include responsive(minMobile) {
            left: 142px;
            top: 227px;
            height: 25px;
            width: 24px;
        }
        @include responsive(minimumMobile) {
            top: 235px;
        }
    }
    &-7 {
        position: absolute;
        left: 35%;
        bottom: 10%;
        height: 10rem;
        z-index: 9;
        @include responsive(mobile) {
            display: none;
        }
    }
    &-8a {
        position: absolute;
        right: 452px;
        bottom: 47px;
        height: 150px;
        width: 150px;
        z-index: 9;
        @include responsive(yettiyuz) {
            right: 357px;
            bottom: 40px;
            height: 70px;
            width: 70px;
        }
        @include responsive(mobile) {
            right: 176px;
            bottom: 25px;
            height: 50px;
            width: 50px;
        }
        @include responsive(uch) {
            right: 176px;
            bottom: 25px;
            height: 50px;
            width: 50px;
        }
        @include responsive(minMobile) {
            right: 166px;
            bottom: 25px;
            height: 50px;
            width: 50px;
        }
    }
    &-8b {
        position: absolute;
        right: 484px;
        bottom: 25px;
        height: 175px;
        width: 175px;
        z-index: 10;
        @include responsive(yettiyuz) {
            right: 365px;
            bottom: 9px;
            height: 100px;
            width: 100px;
        }
        @include responsive(mobile) {
            right: 176px;
            bottom: 7px;
            height: 75px;
            width: 75px;
        }
        @include responsive(uch) {
            right: 176px;
            bottom: 7px;
            height: 75px;
            width: 75px;
        }
        @include responsive(minMobile) {
            right: 169px;
            bottom: 7px;
            height: 75px;
            width: 75px;
        }
    }
    &-9 {
        position: absolute;
        left: 0;
        bottom: 0px;
        height: 240px;
        width: 240px;
        z-index: 9;
        @include responsive(ming) {
            height: 180px;
            width: 180px;
        }
        @include responsive(yettiyuz) {
            height: 120px;
            width: 120px;
        }
        @include responsive(mobile) {
            height: 120px;
            width: 120px;
        }
        @include responsive(uch) {
            height: 100px;
            width: 100px;
        }
    }
    &-10 {
        position: absolute;
        right: 0;
        top: 0;
        height: 48rem;
        width: 40rem;
        @include responsive(ming) {
            height: 42rem;
            width: 34rem;
        }
        @include responsive(yettiyuz) {
            height: 33rem;
            width: 25rem;
        }
        @include responsive(mobile) {
            height: 19rem;
            width: 19rem;
            opacity: 0.5;
            z-index: -20;
        }
    }
    &-11 {
        position: absolute;
        left: 216px;
        top: 383px;
        height: 9rem;
        @include responsive(big) {
            top: 43%;
            left: 8%;
        }
        @include responsive(ming) {
            top: 33%;
            left: 5%;
            height: 5rem;
        }
        @include responsive(yettiyuz) {
            top: 184px;
            left: 21px;
            height: 4rem;
        }
        @include responsive(mobile11) {
            display: none;
        }
        @include responsive(mobile) {
            height: 4.5rem;
            top: 225px;
            left: 56px;
        }
        @include responsive(mobile1) {
            height: 4.5rem;
            top: 237px;
            left: 56px;
        }
        @include responsive(uch) {
            display: none;
        }
        @include responsive(minMobile) {
            display: none;
        }
    }
    &-12 {
        position: absolute;
        right: 5rem;
        bottom: 0;
        height: 10rem;
        @include responsive(yettiyuz) {
            height: 6rem;
            right: 8rem;
        }
        @include responsive(mobile) {
            height: 5rem;
            width: 14rem;
            right: 4rem;
            top: 56.5rem;
        }
    }
}

.header__main--right {
    &--buttons {
        &--1 {
            position: absolute;
            display: flex;
            height: 6rem;
            bottom: 120px;
            right: 100px;
            width: 230px;
            border-radius: 1.2rem;
            text-align: center;
            box-shadow: -10.9px -15.6px 68px rgba(0, 7, 5, 0.7);
            border-radius: 12px;
            @include responsive(ming) {
                width: 170px;
                height: 5rem;
                bottom: 98px;
            }
            @include responsive(yettiyuz) {
                width: 120px;
                height: 3.5rem;
                right: 120px;
                bottom: 60px;
            }
            @include responsive(mobile) {
                width: 111px;
                height: 37px;
                right: 13%;
                bottom: 4%;
            }
            img {
                height: 3rem;
                @include responsive(ming) {
                    height: 2.3rem;
                }
                @include responsive(yettiyuz) {
                    height: 1.8rem;
                }
                @include responsive(mobile) {
                    height: 1.8rem;
                }
            }
            &--left {
                height: 100%;
                background-color: #ff6c8f;
                width: 30%;
                padding-top: 1.4rem;
                border-radius: 12px 0 0 12px;
                @include responsive(yettiyuz) {
                    padding-top: 0.7rem;
                }
                @include responsive(mobile) {
                    height: 37px;
                    border-radius: 5px 0 0 5px;
                    padding-top: 0.7rem;
                }
            }
            &--right {
                background-color: #1a243e;
                padding-left: 1rem;
                padding: 2px;
                padding-top: 1.5rem;
                width: 70%;
                border-radius: 0px 12px 12px 0;
                @include responsive(yettiyuz) {
                    padding-top: 1rem;
                }
                @include responsive(mobile) {
                    height: 37px;
                    border-radius: 0px 5px 5px 0;
                    width: 100px;
                    padding: 0;
                    padding-top: 10px;
                }
                a {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 1.6rem;
                    @include responsive(ming) {
                        font-size: 1.4rem;
                    }
                    @include responsive(yettiyuz) {
                        font-size: 1.1rem;
                    }
                    @include responsive(mobile) {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
    &--buttons--human {
        &--2 {
            position: absolute;
            display: flex;
            width: 22rem;
            height: 7rem;
            right: 1%;
            bottom: -6%;
            text-align: center;
            @include responsive(ming) {
                width: 180px;
                height: 6em;
            }
            @include responsive(yettiyuz) {
                width: 120px;
                height: 3.5rem;
            }
            @include responsive(mobile) {
                width: 111px;
                height: 37px;
                right: 0px;
                bottom: -30px;
            }
            img {
                height: 3rem;
                @include responsive(yettiyuz) {
                    height: 1.6rem;
                }
                @include responsive(mobile) {
                    height: 1.8rem;
                }
            }
            &--left {
                height: 100%;
                background-color: #fbd289;
                width: 40%;
                padding-top: 1.8rem;
                margin-left: -2rem;
                border-radius: 12px 0 0 12px;
                @include responsive(yettiyuz) {
                    padding-top: 1rem;
                }
                @include responsive(mobile) {
                    height: 37px;
                    border-radius: 5px 0 0 5px;
                    padding-top: 0.7rem;
                }
            }
            &--right {
                width: 25rem;
                padding-left: 1rem;
                padding-top: 2rem;
                text-align: center;
                background-color: #242f4c;
                border-radius: 0px 12px 12px 0;
                @include responsive(yettiyuz) {
                    padding-top: 1rem;
                }
                @include responsive(mobile) {
                    height: 37px;
                    border-radius: 0px 5px 5px 0;
                    width: 100px;
                    padding-top: 1rem;
                }
                a {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 1.6rem;
                    @include responsive(yettiyuz) {
                        font-size: 1.2rem;
                    }
                    @include responsive(mobile) {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
    &--buttons--savdo {
        &--2 {
            position: absolute;
            display: flex;
            height: 4rem;
            background: #242f4c;
            width: 18rem;
            bottom: -10%;
            right: 23%;
            border-radius: 0.6rem;
            box-shadow: -10.3px -14.7px 40px rgba(0, 7, 5, 0.24);
            text-align: center;
            @include responsive(ming) {
                width: 14rem;
                right: 32%;
            }
            @include responsive(yettiyuz) {
                width: 120px;
                height: 3rem;
            }
            @include responsive(mobile) {
                width: 110px;
                height: 30px;
                right: 155px;
                bottom: -59px;
            }
            img {
                height: 3rem;
                @include responsive(ming) {
                    height: 2.7rem;
                }
                @include responsive(yettiyuz) {
                    height: 2.2rem;
                }
                @include responsive(mobile) {
                    height: 1.8rem;
                }
            }
            &--left {
                border-radius: 0.6rem 0 0 0.6rem;
                height: 100%;
                background-color: #a66dff;
                width: 30%;
                padding-top: 0.6rem;
                @include responsive(mobile) {
                    border-radius: 5px 0 0 5px;
                    height: 30px;
                    width: 30%;
                    padding-top: 0.7rem;
                }
            }
            &--right {
                padding-left: 1rem;
                text-align: center;
                padding-top: 0.8rem;
                @include responsive(ming) {
                    margin-top: 0.5rem;
                }
                @include responsive(yettiyuz) {
                    margin-top: 0.2rem;
                }
                @include responsive(mobile) {
                    height: 37px;
                    border-radius: 0px 5px 5px 0;
                    width: 70%;
                    padding-left: 0;
                    padding-top: 1rem;
                }
                a {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 1.5rem;
                    @include responsive(ming) {
                        font-size: 1.2rem;
                    }
                    @include responsive(yettiyuz) {
                        font-size: 1rem;
                    }
                    @include responsive(mobile) {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}