@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// font-family: "Montserrat", sans-serif;
	font-family: "Poppins", sans-serif;
}
html {
	font-size: 62.5%;
}
.container {
	display: block;
	width: 90%;
	margin: 0 auto;
}
// QUERY MANAGER

@mixin responsive($breakpoint) {
	@if $breakpoint==mobile {
		@media (max-width: 392px) {
			@content;
		}
	}
}
