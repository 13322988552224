@mixin responsive($breakpoint) {
  @if $breakpoint==mobile {
    @media (max-width: 392px) {
      @content;
    }
  }
  @if $breakpoint==desktop {
    @media (min-width: 1400px) {
      @content;
    }
  }
  @if $breakpoint==big {
    @media (max-width: 1260px) {
      @content;
    }
  }
  @if $breakpoint==toqqiz {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if $breakpoint==yetti {
    @media (max-width: 700px) {
      @content;
    }
  }
  @if $breakpoint==tort {
    @media (max-width: 400px) {
      @content;
    }
  }
}
.box {
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.76);
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.modal {
  z-index: 5000;
  position: fixed;
  // top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 80%;
  background-color: #fff;
  border: 1px solid #3333;
  border-radius: 0.4rem;
  padding: 1rem;
  margin-left: 13rem;
  margin-top: 2rem;
  @include responsive(toqqiz) {
    justify-content: center;
    margin-left: 8rem;
  }
  @include responsive(yetti) {
    justify-content: center;
    margin-left: 5rem;
  }
  @include responsive(tort) {
    justify-content: center;
    margin-left: 3rem;
  }
  &__head {
    height: 10%;

    h2 {
      color: red;
      position: absolute;
      font-size: 3rem;
      color: #333;
      right: 2rem;
      cursor: pointer;
      @include responsive(toqqiz) {
        font-size: 2.4rem;
      }
      @include responsive(tort) {
        font-size: 1.8rem;
      }
    }
    &--draw {
      width: 100%;
      background-color: black;
      height: 2rem;
      margin-bottom: 2rem;
      @include responsive(tort) {
        height: 1rem;
      }
    }
  }
  &__body {
    width: 100%;
    height: auto;
    iframe {
      width: 100%;
      height: 80vh;
      @include responsive(tort) {
        height: 60vh;
      }
    }
  }
}
.modal__box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4000;
}
