@mixin responsive($breakpoint) {
    @if $breakpoint==mobile {
        @media (max-width: 392px) {
            @content;
        }
    }
    @if $breakpoint==desktop {
        @media (min-width: 1400px) {
            @content;
        }
    }
    @if $breakpoint==toqqiz {
        @media (max-width: 900px) {
            @content;
        }
    }
    @if $breakpoint==big {
        @media (max-width: 1260px) {
            @content;
        }
    }
    @if $breakpoint==olti {
        @media (max-width: 665px) {
            @content;
        }
    }
    @if $breakpoint==tort {
        @media (max-width: 450px) {
            @content;
        }
    }
    @if $breakpoint==uch {
        @media (max-width: 376px) {
            @content;
        }
    }
    @if $breakpoint==minMobile {
        @media (max-width: 355px) {
            @content;
        }
    }
    @if $breakpoint==minimumMobile {
        @media (max-width: 325px) {
            @content;
        }
    }
}

.suggestions__box {
    display: block;
    width: 100%;
    margin: 0 auto;
    @include responsive(desktop) {
        width: 1460px;
    }
    @include responsive(big) {
        width: 100%;
    }
    @include responsive(mobile) {
        width: 100%;
        height: auto;
    }
}

.suggestions {
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    padding: 5rem 0;
    padding-bottom: 8rem;
    @include responsive(mobile) {
        padding-bottom: 1rem;
        width: 100%;
    }
    &-top {
        width: 100%;
        margin: 0 auto;
        &__list {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
        &__btn {
            outline: none;
            padding: 0 20px;
            border: transparent;
            background-color: transparent;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 45px;
            color: #191919;
            cursor: pointer;
            &--active {
                background: #dddddd;
                border-radius: 0.5rem;
            }
            @include responsive(big) {
                font-size: 18px;
            }
            @include responsive(mobile) {
                font-size: 13px;
                padding: 0 10px;
            }
        }
    }
    &-middle {
        &__item {
            margin-bottom: 2rem;
        }
        margin: 0 auto;
        margin-top: 5rem;
        &__list {
            display: grid;
            list-style-type: none;
            grid-template-columns: repeat(3, 1fr);
            @include responsive(big) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include responsive(olti) {
                grid-template-columns: repeat(1, 1fr);
                width: 100%;
            }
        }
        &-card {
            @include responsive(big) {
                margin-bottom: 3rem;
                width: 90%;
            }
            // &__box {
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            // }
            &__img {
                display: block;
                margin: 0 auto;
                border-radius: 1.4rem;
                margin-bottom: 20px;
                width: 386px;
                // height: 386px;
                @include responsive(big) {
                    width: 90%;
                    height: 220px;
                }
                @include responsive(mobile) {
                    width: 215px;
                    height: 155px;
                    margin-bottom: 1rem;
                }
            }
            &__subject {
                margin-top: 2.9rem;
                font-weight: 400;
                font-size: 25.0046px;
                color: #e04776;
                text-align: left;
                // padding-left: 3rem;
                @include responsive(big) {
                    font-size: 16px;
                }
                @include responsive(mobile) {
                    display: block;
                    text-align: left;
                    display: block;
                    margin: 0.3rem auto;
                    font-size: 16px;
                    width: 72%;
                    font-weight: 600;
                }
            }
            &__text {
                margin-top: 6px;
                font-weight: 600;
                font-size: 22.0046px;
                line-height: 1.4;
                color: #191919;
                letter-spacing: 0.1rem;
                @include responsive(big) {
                    font-size: 13px;
                }
                @include responsive(mobile) {
                    width: 272px;
                    letter-spacing: 0rem;
                    text-align: left;
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 2rem;
                    font-size: 14px;
                    margin-left: 45px;
                    line-height: 21px;
                    font-weight: 600;
                }
                @include responsive(minMobile) {
                    margin-left: 39px;
                }
                @include responsive(minimumMobile) {
                    width: 222px;
                    margin-left: 35px;
                }
                &--2 {
                    @include responsive(mobile) {
                        width: 259px;
                        width: 222px;
                    }
                    @include responsive(minimumMobile) {
                        width: 222px;
                    }
                }
                &--3 {
                    @include responsive(mobile) {
                        width: 226px;
                    }
                }
            }
        }
    }
}

.book {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 400px;
    margin-bottom: 18rem;
    @include responsive(toqqiz) {
        height: 340px;
        margin-bottom: 0;
    }
    @include responsive(tort) {
        margin-bottom: 3rem;
        height: 300px;
    }
    &__img {
        display: block;
        margin: 0 auto;
        width: 514px;
        height: 427px;
        border-radius: 24px;
        object-fit: cover;
        @include responsive(toqqiz) {
            height: 300px;
            width: 380px;
        }
        @include responsive(tort) {
            height: 200px;
            width: 280px;
            border-radius: 1rem;
        }
        @include responsive(mobile) {
            height: 200px;
            width: 240px;
            border-radius: 1rem;
        }
    }
    &__body {
        display: block;
        margin: 0 auto;
        width: 500px;
        padding: 0.5rem;
        @include responsive(toqqiz) {
            width: 400px;
        }
        @include responsive(olti) {
            width: 351px;
        }
        @include responsive(tort) {
            width: 301px;
        }
        @include responsive(mobile) {
            width: 260px;
            // margin-bottom: 2rem;
        }
    }
    &__link {
        color: #e04776;
        font-size: 36px;
        @include responsive(toqqiz) {
            font-size: 24px;
        }
        @include responsive(tort) {
            font-size: 16px;
        }
    }
    &__parag {
        width: 100%;
        font-size: 24px;
        // margin-bottom: 20px;
        @include responsive(toqqiz) {
            font-size: 16px;
        }
        @include responsive(tort) {
            font-size: 13px;
        }
    }
}

.qora__link {
    width: 100%;
    max-width: 386px;
    margin: 0 auto;
    color: #191919;
    @include responsive(toqqiz) {
        width: 100%;
        max-width: 286px;
    }
    @include responsive(olti) {
        width: 100%;
        max-width: 186px;
    }
    @include responsive(tort) {
        width: 100%;
        max-width: 206px;
    }
}

.card__bottom {
    width: 100%;
    padding: 0 3.4rem;
}

.card__body {
    display: block;
    margin: 0 auto;
    width: 85%;
}