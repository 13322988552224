@mixin responsive($breakpoint) {
    @if $breakpoint==big {
        @media (min-width: 1300px) {
            @content;
        }
    }
    @if $breakpoint==desktop {
        @media (max-width: 1200px) {
            @content;
        }
    }
    @if $breakpoint==oltmish {
        @media (max-width: 960px) {
            @content;
        }
    }
    @if $breakpoint==yetti {
        @media (max-width: 770px) {
            @content;
        }
    }
    @if $breakpoint==tablet {
        @media (max-width: 900px) {
            @content;
        }
    }
    @if $breakpoint==planshet {
        @media (max-width: 630px) {
            @content;
        }
    }
    @if $breakpoint==mobile {
        @media (max-width: 530px) {
            @content;
        }
    }
    @if $breakpoint==tort {
        @media (max-width: 430px) {
            @content;
        }
    }
    @if $breakpoint==tort2 {
        @media (max-width: 420px) {
            @content;
        }
    }
    @if $breakpoint==small {
        @media (max-width: 350px) {
            @content;
        }
    }
    @if $breakpoint==olti {
        @media (max-width: 630px) {
            @content;
        }
    }
}

button {
    outline: none;
}

svg {
    outline: none;
}

.directions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: auto;
    position: relative;
    width: 100%;
    margin: 0 auto;
    @include responsive(desktop) {
        width: 100%;
    }
    @include responsive(mobile) {
        width: 100%;
        height: auto;
    }
    background: linear-gradient( 20.49deg, #11172a 23.78%, #1b233b 57.45%, #2f3a5e 91.48%, rgba(17, 23, 42, 0.93) 91.48%);
    &-top {
        display: flex;
        width: 69%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 7rem;
        padding: 0;
        @include responsive(mobile) {
            width: 90%;
            margin-top: 3rem;
        }
        &__subject {
            font-weight: 700;
            font-size: 40px;
            color: #ffffff;
            @include responsive(desktop) {
                font-size: 24px;
            }
            @include responsive(planshet) {
                font-size: 18px;
            }
            @include responsive(mobile) {
                font-size: 12px;
            }
        }
        &__text {
            width: 85%;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            color: #ffffff;
            margin: 2rem 5px;
            margin-bottom: 10rem;
            @include responsive(desktop) {
                font-size: 18px;
                margin-bottom: 2rem;
            }
            @include responsive(yetti) {
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                margin-left: -4.6rem;
            }
            @include responsive(planshet) {
                font-size: 14px;
                margin-left: 0rem;
                text-align: center;
                line-height: 20px;
            }
            @include responsive(mobile) {
                font-size: 11px;
                z-index: 22;
                // margin-bottom: 2rem;
                margin-bottom: 0;
                line-height: 16px;
                // text-align: left;
            }
        }
    }
    &-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        width: 100%;
        &__list {
            padding: 0;
            display: flex;
            list-style-type: none;
            flex-wrap: wrap;
            margin: 0 auto;
            width: 96%;
            @include responsive(desktop) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
            }
            @include responsive(planshet) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
            }
            @include responsive(mobile) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                width: 100%;
            }
        }
        &__item {
            position: relative;
            width: 21%;
            border-radius: 1rem;
            background-image: url("../../Assets/images/bg.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            filter: drop-shadow(-21.8px -31.1px 38.8px rgba(0, 7, 5, 0.2));
            margin: 0 auto;
            margin-bottom: 5rem;
            @include responsive(desktop) {
                width: 95%;
                height: 34.1rem;
            }
            @include responsive(oltmish) {
                width: 95%;
                height: 37.1rem;
            }
            @include responsive(yetti) {
                padding: 1rem 0;
            }
            @include responsive(mobile) {
                width: 75%;
                height: 25rem;
                margin-bottom: 2rem;
            }
            @include responsive(tort2) {
                height: 25rem;
                margin: 0 0.5rem;
                margin-bottom: 2rem;
            }
            @include responsive(small) {
                margin: 0 auto;
                margin-bottom: 2rem;
            }
        }
        &__card {
            margin-top: 3.2rem;
            margin-left: 2.8rem;
            width: 260px;
            height: 326px;
            @include responsive(desktop) {
                width: 100%;
            }
            @include responsive(yetti) {
                margin-top: 0.6rem;
                margin-left: 2.5rem;
            }
        }
        &-card {
            &__box {
                border-radius: 50%;
                width: 6em;
                height: 6em;
                text-align: center;
                padding-top: 1.2rem;
                @include responsive(mobile) {
                    width: 31px;
                    height: 31px;
                }
                img {
                    @include responsive(yetti) {
                        // width: 2rem;
                    }
                }
                &--1 {
                    background-color: #fbd289;
                    img {
                        width: 4.5rem;
                        height: 4.5rem;
                        margin-top: -0.5rem;
                        @include responsive(mobile) {
                            width: 25px;
                            height: 25px;
                            margin-top: -0.8rem;
                        }
                    }
                }
                &--2 {
                    background-color: #a66dff;
                    img {
                        margin-top: -0.7rem;
                        width: 5rem;
                        height: 5rem;
                        @include responsive(mobile) {
                            width: 25px;
                            height: 25px;
                            margin-top: -0.8rem;
                        }
                    }
                }
                &--3 {
                    background-color: #ff6c8f;
                    img {
                        margin-top: -0.1rem;
                        @include responsive(mobile) {
                            width: 25px;
                            height: 25px;
                            margin-top: -0.8rem;
                        }
                    }
                }
                &--4 {
                    background-color: #fbd289;
                    img {
                        margin-top: -0.1rem;
                        @include responsive(mobile) {
                            width: 22px;
                            height: 22px;
                            margin-top: -0.8rem;
                        }
                    }
                }
                &--5 {
                    background-color: #27aaf0;
                    img {
                        margin-top: -0.1rem;
                        @include responsive(mobile) {
                            width: 20px;
                            height: 20px;
                            margin-top: -0.5rem;
                        }
                    }
                }
                &--6 {
                    background-color: #ff6b4e;
                    img {
                        margin-top: -0.5rem;
                        @include responsive(mobile) {
                            width: 20px;
                            height: 20px;
                            margin-top: -0.8rem;
                        }
                    }
                }
                &--7 {
                    background-color: #a66dff;
                    img {
                        margin-top: -0.2rem;
                        @include responsive(mobile) {
                            width: 20px;
                            height: 25px;
                            margin-top: -0.8rem;
                        }
                    }
                }
                &--8 {
                    background-color: #fbd289;
                    img {
                        margin-top: -0.5rem;
                        @include responsive(mobile) {
                            width: 22px;
                            height: 22px;
                            margin-top: -0.8rem;
                        }
                    }
                }
            }
            &__subject {
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 33px;
                display: flex;
                align-items: flex-end;
                color: #fff;
                margin-bottom: 5px;
                margin-top: 1rem;
                width: 80%;
                @include responsive(desktop) {
                    font-size: 16px;
                    width: 70%;
                }
                @include responsive(yetti) {
                    font-size: 14px;
                    line-height: 20px;
                    width: 80%;
                }
                @include responsive(planshet) {
                    font-size: 13px;
                }
                @include responsive(mobile) {
                    font-size: 14px;
                }
            }
            &__text {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 22px;
                display: flex;
                align-items: flex-end;
                color: #ffffff;
                margin-bottom: 80px;
                width: 70%;
                @include responsive(desktop) {
                    font-size: 13px;
                    width: 70%;
                }
                @include responsive(yetti) {
                    font-size: 13px;
                    line-height: 20px;
                    width: 80%;
                }
                @include responsive(planshet) {
                    font-size: 11px;
                    line-height: 15px;
                }
                @include responsive(mobile) {
                    font-size: 7px;
                    line-height: 10px;
                    margin-bottom: 10px;
                }
            }
            &__btn {
                outline: none;
                position: absolute;
                text-align: center;
                bottom: -1.6rem;
                left: 2.4rem;
                padding: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 14.3051px;
                line-height: 21px;
                color: #ffffff;
                background: #a66dff;
                margin-bottom: 3rem;
                border: transparent;
                border-radius: 1.2rem;
                cursor: pointer;
                @include responsive(mobile) {
                    border-radius: 0.5rem;
                }
                &--1 {
                    background-color: #fbd289;
                }
                &--2 {
                    background-color: #a66dff;
                }
                &--3 {
                    background-color: #ff6c8f;
                }
                &--4 {
                    background-color: #fbd289;
                }
                &--5 {
                    background-color: #27aaf0;
                }
                &--6 {
                    background-color: #ff6b4e;
                }
                &--7 {
                    background-color: #a66dff;
                }
                &--8 {
                    background-color: #fbd289;
                }
                &--9 {
                    background-color: #00cc82;
                }
            }
        }
    }
    &-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -5rem;
        @include responsive(mobile) {
            margin-top: -3rem;
        }
        &__btn {
            outline: none;
            cursor: pointer;
            padding: 0;
            margin: 4rem 0;
            width: 25%;
            height: 5%;
            background-color: #fbd289;
            border: none;
            padding: 1.4rem;
            font-size: 1.6rem;
            font-weight: 900;
            width: 20rem;
            color: #fff;
            border-radius: 1rem;
            @include responsive(mobile) {
                width: 130px;
                height: 35px;
                font-size: 1.1rem;
                padding: 0.7rem;
            }
        }
    }
}

.directions__items {
    margin-left: 25px;
}

.directions-middle-card__img {
    width: 4rem;
    height: 4rem;
    text-align: center;
}

// Splide
.directions {
    &__splide {
        display: none;
    }
    &__splides {
        display: none;
    }
}

@media (max-width: 720px) {
    .container {
        width: 600px;
    }
    .directions {
        &-bottom {
            display: none;
        }
        &-middle {
            display: none;
            &-card__text {
                width: 200px;
            }
        }
        &__splides {
            display: none;
        }
        &__splide {
            display: block;
        }
        &__splide--item {
            display: flex;
        }
        &-middle-card__text {
            font-size: 12px;
            margin-left: -8px;
        }
        &-middle__item {
            width: 230px;
            height: 340px;
        }
        &__title {
            display: flex;
            margin: 40px;
            margin-top: -10px;
        }
        &-middle-card__box {
            margin-left: -8px;
        }
        &-middle-card__subject {
            margin-left: -8px;
        }
    }
}

.splide__slide {
    height: 380px;
}

@media (max-width: 620px) {
    .container {
        width: 500px;
    }
    .directions {
        &-middle__item {
            width: 210px;
            height: 335px;
        }
        &__title {
            margin: 15px;
        }
        &-middle-card__text {
            font-size: 10px;
            width: 160px;
        }
        &-middle-card__img {
            width: 40px;
            height: 40px;
            padding: 5px;
        }
        &-middle-card__box {
            width: 50px;
            height: 55px;
        }
        &-middle-card__btn {
            outline: none;
            margin-left: -5px;
        }
    }
}

@media (max-width: 520px) {
    .container {
        width: 400px;
    }
    .splide__slide img {
        width: 40px;
        height: 40px;
    }
    .directions {
        &-middle__item {
            width: 160px;
            height: 260px;
            margin-top: 20px;
        }
        &-middle-card__box {
            margin-left: -10px;
        }
        &__title {
            margin: 0 35px;
        }
        &-middle-card__text {
            font-size: 9px;
            line-height: 16px;
            width: 130px;
            margin-left: -10px;
        }
        &-middle-card__subject {
            font-size: 13px;
            line-height: 21px;
            margin-left: -10px;
        }
        &-middle-card__btn {
            outline: none;
            margin-left: -10px;
        }
    }
}

@media (max-width: 420px) {
    .container {
        width: 310px;
    }
    .directions {
        &-middle__item {
            width: 130px;
            height: 200px;
            margin-top: 40px;
        }
        &-middle-card__text {
            font-size: 7px;
            line-height: 10px;
            width: 90px;
            margin-left: -15px;
        }
        &-middle-card__subject {
            font-size: 11px;
            line-height: 13px;
            margin-left: -15px;
        }
        &-middle-card__box {
            width: 40px;
            height: 40px;
            margin-left: -15px;
        }
        &-middle-card__btn {
            width: 70px;
            height: 18px;
            font-size: 9px;
            line-height: 10px;
            margin-left: -15px;
            outline: none;
        }
    }
    .splide__slide img {
        width: 35px;
        height: 32px;
    }
}

@media (max-width: 380px) {
    .directions-middle-card {
        height: 228px;
    }
}

@media (max-width: 350px) {
    .directions {
        &__splides {
            display: block;
        }
        &__splide {
            display: none;
        }
        &-middle__item {
            width: 220px;
            height: 310px;
            margin-top: 40px;
            padding: 2px;
        }
        &-middle-card__text {
            font-size: 11px;
            line-height: 18px;
            width: 200px;
            padding: 10px;
        }
        &-middle-card__subject {
            font-size: 14px;
            list-style: 18px;
            padding: 10px;
        }
        &-middle-card__box {
            width: 50px;
            height: 50px;
            margin-left: -5px;
        }
        &-middle-card__btn {
            width: 90px;
            height: 25px;
            margin-left: 0px;
            outline: none;
        }
        &__items {
            margin-left: 45px;
        }
    }
    .splide__slide img {
        width: 40px;
        height: 40px;
    }
}

//Modal
.directions {
    &__modal-name {
        font-size: 25px;
    }
    &__modal-text {
        font-size: 18px;
    }
    &__modal-btn {
        outline: none;
        width: 120px;
        height: 30px;
        background-color: #00cc82;
        border: none;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
        margin-top: 10px;
    }
}

.sariq {
    background-color: #00cc82;
}

.directions__modal-name {
    @include responsive(olti) {
        font-size: 1.5rem;
    }
}

.directions__modal-text {
    @include responsive(olti) {
        font-size: 1.2rem;
    }
}

.directions__modal-btn {
    @include responsive(olti) {
        width: 5rem;
        outline: none;
    }
}

.directions-middle-card__box--4 {
    background-color: #00cc82;
}

.directions-middle__item {
    @include responsive(olti) {
        height: 280px;
    }
    @include responsive(tort) {
        height: 290px;
    }
    @include responsive(tort2) {
        height: 220px;
    }
    @include responsive(small) {
        height: 310px;
    }
}

.modal__section {
    z-index: 3000;
}

.dizayn {
    position: absolute;
    display: none;
    @include responsive(yetti) {
        font-size: 13px;
        line-height: 20px;
        width: 80%;
    }
    @include responsive(planshet) {
        font-size: 11px;
        line-height: 15px;
    }
    @include responsive(mobile) {
        display: inline-block;
        width: 100px;
        margin-left: -80%;
    }
}